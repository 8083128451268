import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "OTcyYjljMWZjYTI1NmU3YWYxYmQ5MGJiNjQ5ODI1MTc=",
	"Tenant-Access-Key": "5aBf0ec2wGv_QA",
};

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
